<template>
  <div>
     <b-modal id="openSerList" class="modal-header" :title="modalTitle" ok-only scrollable size="xxl">
         <transcations-table :trxid="data.trxid" :category="data.category" :sid="data.sid" :netType="data.netType" :edate="data.edate" :sdate="data.sdate" :netHost="data.netHost"></transcations-table>
      
     </b-modal>
  </div>
</template>
<script>
// Charts
import TranscationsTable from './manager/Transcations/TranscationsTable.vue'

export default {
  components: {
    TranscationsTable,
  },
  data() {
    return {
      trxid: '',
      netType:0,
      data: {},
      sdate: '',
      edate: '',
      operator: '',
    };
  },
  computed: {
    modalTitle() {
      if (this.data.sprovidername) {
        return `Transactions - ${this.data.sprovidername} - ${this.data.sname || ''}`.trim();
      } else if (this.data.category) {
        return `Transactions - ${this.data.category}`;
      } else if (this.operator) {
        return `Transactions - ${this.operator}`;
      } else if (this.data.netHost) {
        return `Transactions - ${this.data.netHost}`;
      }
      else {
        return 'Transactions';
      }
    }
  },
  mounted() {


     this.$root.$on("showtrxforlist", (data) => {
   

      console.log("DATA", data);
         this.data = data; 
         if(data._id){this.trxid = data._id};   
         if(data.sdate){this.sdate = data.sdate};
         if(data.edate){this.edate = data.edate};
         if(data.netType){this.netType = data.netType};
         if(data.operator){this.operator = data.operator};
        });
        
  },
};
</script>
<style scoped>
.modal-header {
 color: #1d75bc;
}
.text-blue {
  color: #1d75bc;
}
.table .acClass {
  background-color: red;
}
.table .bTableThStyle {
  background-color: blue !important;
  /* max-width: '100px' !important; */
  text-overflow: ellipsis !important;
}
.table > tbody > tr > td {
  max-width: 350px !important;
  /* text-justify: auto; */
  width: 1000px;
  /* white-space: nowrap; */
  overflow: auto;
  text-overflow: ellipsis;
}
.modal-dialog-1 {
  position: fixed;
  margin: auto;

  height: 100%;
  right: 0px;
}
.modal-content-1 {
  height: 100%;
}
/* .el-table .cell {
  max-width: 150px;
  padding-left: 0px;
  padding-right: 0px;
} */
</style>
